import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import Home from './Home';

const config = {
  clientId: '0oa962h1oSu32idSj4x6',
  issuer: 'https://xlio.okta.com/',
  redirectUri: process.env.NODE_ENV === 'production' ? 'https://startupstudio.xl.io/implicit/callback' : 'http://localhost:3000/implicit/callback',
  scopes: ['openid', 'okta.users.read.self'],
  pkce: true
};

function App() {
  return (
    <Router>
      <Security {...config}>
        <SecureRoute path='/' exact={true} component={Home}/>
        <Route path='/implicit/callback' component={LoginCallback}/>
      </Security>
    </Router>
  );
}

export default App;
