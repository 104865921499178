import React from 'react';
import Header from './Header';
import './Home.css';

const Home = () => {
  return (
    <>
      <header>
        <Header/>
      </header>
      <main>
        <div className="background"></div>
        <div className="container-fluid">
          <div className="card my-3" onClick={() => window.location.href = "https://xlio.okta.com/home/atlassian/0oa92oei6nuHaTxZt4x6/aln1aqcs056BwpE4X0g8"}>
            <img src="Confluence-blue.svg" alt="Confluence" className="card-image mr-auto ml-auto mt-4"/>
            <div className="card-body">
              Project status and updates.
            </div>
          </div>
          <div className="card my-3" onClick={() => window.location.href = "https://xlio.okta.com/home/slack/0oa962barl7rctdeb4x6/19411"}>
            <img src="Slack_RGB.svg" alt="Slack" className="card-image mr-auto ml-auto mt-4"/>
            <div className="card-body">
              Team communication.
            </div>
          </div>
          <div className="card my-3" onClick={() => window.location.href = "https://xlio.okta.com/home/atlassian/0oa92oei6nuHaTxZt4x6/aln1aqcs055ZRoizW0g8"}>
            <img src="Jira Software-blue.svg" alt="Jira" className="card-image mr-auto ml-auto mt-4"/>
            <div className="card-body">
              Issue tracking.
            </div>
          </div>
          <div className="card my-3" onClick={() => window.location.href = "https://xlio.okta.com/home/amazon_aws/0oa961fgaYXCXj1u84x6/272"}>
            <img src="Amazon_Web_Services_Logo.svg" alt="AWS" className="card-image mr-auto ml-auto mt-4"/>
            <div className="card-body">
              Hosting and infrastructure.
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;