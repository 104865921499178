import { Navbar, NavbarBrand, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Header = () => {
  const {authState, authService} = useOktaAuth();
  const [userInfo, setUserInfo]  = useState();

  useEffect(() => {
    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      fetch("https://xlio.okta.com/api/v1/users/me", {
        headers: {
          Authorization: 'Bearer ' + authState.accessToken,
        }
      }).then(response => response.json().then(v => setUserInfo(v)));
    }
  }, [authState, authService]);

  const logout = async () => authService.logout('/');

  const userProfile = userInfo?.profile;
  const userName = userProfile ? `${userProfile.firstName} ${userProfile.lastName}` : "";

  return (
    <Navbar color="dark" dark expand="lg">
      <NavbarBrand><img src="/XLIOblue.png" alt="XLIO" height={30}/></NavbarBrand>
      <Nav navbar className="ml-auto">
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            {userName}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={logout}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};

export default Header;